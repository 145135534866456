import React from "react";

function Operations() {
  return (
    <div className="content__wrapper">
      <h1>Operations</h1>
      <div>
        <p className="intro">
          South Prairie Aiport (02WA) is a private airstrip. However, if you
          know someone on the airport and want to drop in and say hello, we
          welcome it.
        </p>
        <h2>General Information</h2>
        <ul>
          <li>Left traffic.</li>
          <li>TPA: 1500 MSL.</li>
          <li>CTAF: 122.90</li>
          <li>Numerous non-radio aircraft.</li>
          <li>Use caution for runway mowing activity.</li>
          <li>Taxiway is west side of runway.</li>
          <li>
            No run ups in front of houses. Designated run up areas are SE corner
            and NW corner.
          </li>
          <li>
            When landing south, make sure you clear the road by a safe margin.
          </li>
          <li>
          When landing north, plan to be stopped before you reach the road at the north end of
          the runway. Use caution for cars crossing runway on road.
        </li>
        </ul>
        
        <h2>Runway Conditions</h2>
       
        <p>Please avoid any areas of the runway that are under repair.</p>
        
        <h2>Safety Tips</h2>
        <p>
          Elk, deer and people can often be found enjoying the runway. Please
          keep a watchful eye out.
        </p>
      </div>
    </div>
  );
}

export default Operations;
