import axios from "axios";
// import Promise from "Promise";
const getConditions = () => {
  let key = '9cc3d776371e451983d776371ee51921';
  let url = `https://api.weather.com/v2/pws/observations/current?stationId=KWABUCKL102&format=json&units=e&apiKey=${key}`;
  return axios({
    url: url,
    method: "GET",
    // headers: {
    //   Authorization: "Bearer keycN2U7BBmQfMvw8",
    // },
  }).then((resp) => {
    console.log("resp: ", resp.data.observations[0]);
    return resp.data.observations[0];
  });
};


const appRequest = { getConditions };
export { getConditions };
export default appRequest;
